<template>
  <SearchHeader
    :showSidebar="showSidebar"
    v-model:filters="filtersComputed"
    @reloadClicked="search"
    @toggleFilterSidebarClicked="toggleFilterSidebar"
  />
  <div class="c-discount-search-result">
    <div class="flex">
      <div class="flex-auto">
        <Card>
          <template #content>
            <div>
              <Suspense>
                <SearchDiscountList
                  :discounts="discounts"
                  :loading="loading"
                  :totalHits="totalHits"
                  :pageSize="pageSize"
                  :page="page"
                  :sortField="sortField"
                  :sortOrder="sortOrder"
                  :employeeId="employeeId"
                  @update:page="onUpdatePage"
                  @update:sortOrder="onUpdateSortOrder"
                  @update:sortField="onUpdateSortField"
                  @rowDblClicked="openUpdateDiscount"
                  @update:pageSize="onUpdatePageSize"
                  @refreshList="search"
                />
              </Suspense>
            </div>
          </template>
        </Card>
      </div>
      <div class="pb-2" :class="showSidebar ? 'pl-5' : ''">
        <FilterSidebar :showSidebar="showSidebar" v-model:filters="filtersComputed" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchHeader from "../components/SearchHeader.vue";
import { computed, onMounted, ref, watch } from "vue";
import { DiscountSearchFilters } from "../model/search/discount/DiscountSearchFilters";
import FilterSidebar from "../components/FilterSidebar.vue";
import SearchDiscountList from "../components/SearchDiscountList.vue";
import { SearchDiscount } from "../model/search/discount/SearchDiscount";
import { DiscountSearchRequest } from "../model/search/discount/DiscountSearchRequest";
import { useRoute, useRouter } from "vue-router";
import { useSearchService } from "../api/search/SearchService";
import { DiscountRoutes } from "../routes";
import { useAuth } from "@cumulus/event-bus";
import { NIL as emptyUuid } from "uuid";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useI18n } from "vue-i18n";

const toast = useCumulusToast(useToast());
const { getUser } = useAuth();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { discountSearch } = useSearchService();

const discounts = ref<SearchDiscount[]>([]);
const showSidebar = ref(true);
const filters = ref(new DiscountSearchFilters());
const loading = ref(false);
const totalHits = ref(0);
const pageSize = ref<number>(50);
const page = ref(1);
const query = ref("");
const sortField = ref("");
const sortOrder = ref(-1);

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = newQuery as string;
      loading.value = true;
      await search();
    }
  }
);

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const toggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const search = async () => {
  try {
    loading.value = true;
    const request = new DiscountSearchRequest(query.value);

    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;
    const response = await discountSearch(request);
    discounts.value = response.discounts;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const openUpdateDiscount = (discountId: string) => {
  discountId != null && discountId !== emptyUuid
    ? router.push({ name: DiscountRoutes.Update, params: { id: discountId } })
    : toast.add({
        severity: "warn",
        summary: t("discount.not-found"),
        detail: t("discount.not-found"),
        closable: true,
      });
};

const filtersComputed = computed<DiscountSearchFilters>({
  get: () => filters.value,
  set: async (value: DiscountSearchFilters) => {
    filters.value = value;
    loading.value = true;
    await search();
  },
});

const employeeId = ref<string>("");
onMounted(async () => {
  employeeId.value = (await getUser()).getEmployee().id;

  query.value = route.query.search as string;
  await search();
});
</script>

<style scoped lang="scss">
.c-discount-search-result {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
</style>
