<template>
  <div class="p-inputgroup c-minimal-inputtext c-minimal-inputgroup c-datatable-header">
    <InputText
      v-model="filters['global'].value"
      :placeholder="t('discount.product-group.filter')"
      class="w-full"
      ref="groupLevelSearchInput"
      :data-testid="`c-discount-product-group-filter-${level}`"
    />
    <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-filter text-zinc-500" /></span>
  </div>
  <DataTable
    :value="productGroupNames"
    dataKey="id"
    class="c-compact-datatable"
    :loading="loading"
    @row-select="onRowSelected"
    :stripedRows="true"
    selectionMode="single"
    tabindex="0"
    responsiveLayout="scroll"
    v-model:filters="filters"
    :globalFilterFields="['global', 'name']"
    scrollable
    scrollHeight="400px"
    :data-testid="`c-discount-product-group-list-${level}`"
  >
    <Column field="name" :header="t('discount.product-group.label')" filterField="name" sortable>
      <template #body="{ data, field, index }">
        <span :data-testid="`product-group-${field}-row-${index}`">
          {{ data[field] }}
        </span>
      </template>
    </Column>

    <template #empty>
      {{ t("discount.product-group.no-found") }}
    </template>
  </DataTable>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { FilterMatchMode } from "@primevue/core/api";
import { ProductGroupNameByLanguage } from "../../model/product/ProductGroupNameByLanguage";
import { DataTableRowSelectEvent } from "primevue/datatable";
import { ProductHierarchy } from "../../model/product/ProductHierarchy";

defineProps<{
  productGroupNames: ProductGroupNameByLanguage[];
  productHierarchies: ProductHierarchy[];
  loading: boolean;
  level: number;
}>();

const emit = defineEmits<{
  (event: "productGroupSelected", data: ProductGroupNameByLanguage): void;
}>();

const { t } = useI18n();
const groupLevelSearchInput = ref();

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});

const onRowSelected = (event: DataTableRowSelectEvent) => {
  emit("productGroupSelected", event.data);
};

const focusSearchInput = () => {
  groupLevelSearchInput.value?.$el.focus();
};

defineExpose({
  focusSearchInput,
});
</script>
