<template>
  <PrimeButton class="c-circular-button mr-4" @click="onAddDiscountClicked" data-testid="btn-add">
    <i class="pi pi-plus c-success-button c-circular-icon"></i>
    <span class="px-4">{{ t("common.add") }}</span>
  </PrimeButton>
  <PrimeButton class="c-circular-button mr-4" @click="emit('reloadClicked')" data-testid="c-reload-button">
    <i class="pi pi-refresh c-default-button c-circular-icon"></i>
    <span class="px-4">{{ t("common.reload") }}</span>
  </PrimeButton>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { DiscountRoutes } from "../routes";

const emit = defineEmits<{
  (e: "reloadClicked"): void;
}>();

const { t } = useI18n();
const router = useRouter();

const onAddDiscountClicked = () => {
  router.push({ name: DiscountRoutes.Add });
};
</script>
