<template>
  <div class="c-minimal-inputtext c-minimal-inputgroup">
    <div class="p-inputgroup">
      <InputText
        ref="searchInput"
        v-model="customerGroupNameQuery"
        v-debounce:200="search"
        class="w-72"
        :placeholder="t('discount.customer-group.search')"
        @keydown.enter.prevent="focusSearchResult"
        @keydown.tab="focusSearchResult"
        @keydown.esc="handleEscape($event)"
        @input="showSearchOverlayPanel($event)"
        @focus="showSearchOverlayPanel($event)"
        aria-controls="customer_group_overlay_panel"
        data-testid="discount-customer-group-search"
      />
      <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-search text-zinc-500" /></span>
    </div>
  </div>
  <Popover
    ref="overlayPanelRef"
    appendTo="body"
    :showCloseIcon="false"
    :dismissable="searchResultPanelDismissable"
    id="customer_group_overlay_panel"
    :breakpoints="{ '500px': '100vw' }"
    :style="{ width: '500px' }"
    @keydown.esc.stop="hideSearchOverlayPanel"
  >
    <CustomerGroupSearchList
      ref="customerGroupSearchListRef"
      :customerGroups="customerGroups"
      :loading="loading"
      @customerGroupSelected="onCustomerGroupSelected"
    />
  </Popover>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useDuplicatedDataService } from "../api/price/DuplicatedDataService";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";
import CustomerGroupSearchList from "./CustomerGroupSearchList.vue";

const emit = defineEmits<{
  (e: "customerGroupSelected", value: CustomerGroup): void;
}>();

const { t } = useI18n();
const { getCustomerGroupsByName } = useDuplicatedDataService();

const customerGroupNameQuery = ref<string>("");
const customerGroups = ref<CustomerGroup[]>([]);
const loading = ref<boolean>(false);
const overlayPanelRef = ref();
const searchResultPanelDismissable = ref(true);
const selectedCustomerGroup = ref<CustomerGroup | null>(null);
const customerGroupSearchListRef = ref<ComponentPublicInstance<HTMLInputElement>>();
const searchInput = ref();

const onCustomerGroupSelected = (customer: CustomerGroup) => {
  emit("customerGroupSelected", customer);
  hideSearchOverlayPanel();
};

const search = async () => {
  try {
    loading.value = true;
    customerGroups.value = await getCustomerGroupsByName(customerGroupNameQuery.value);
  } finally {
    loading.value = false;
  }
};

const focusSearchResult = () => {
  if (customerGroups.value.length > 0 && customerGroupSearchListRef.value) {
    nextTick(() => {
      const firstTableRow = customerGroupSearchListRef.value?.$el.querySelector("tbody tr:first-of-type");
      if (firstTableRow) {
        firstTableRow.focus();
      }
    });
  }
};

const handleEscape = (event: KeyboardEvent) => {
  if (customerGroupNameQuery.value !== "") {
    customerGroupNameQuery.value = "";
    hideSearchOverlayPanel();
    event.stopPropagation();
  }
};

const hideSearchOverlayPanel = () => {
  searchInput.value.$el.focus();
  selectedCustomerGroup.value = null;
  overlayPanelRef.value.hide();
};

const showSearchOverlayPanel = (event: Event) => {
  if (customerGroupNameQuery.value === "") {
    overlayPanelRef.value.hide();
    return;
  }
  overlayPanelRef.value.show({ currentTarget: event.target });
};
</script>
<style scoped lang="scss">
:deep(input.p-inputtext.p-component) {
  border: none;
  border-bottom: var(--floating-input-border);
  border-radius: 0;
  padding: 0.25rem;
}
</style>
